import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Col, Drawer, Form, Row, Select, Space} from "antd";
import {AreaChartOutlined, BarChartOutlined, LineChartOutlined, PieChartOutlined} from "@ant-design/icons";
import {
    AreaChartCustom,
    BarChartCustom,
    BarRaceCustom,
    BubbleChartCustom,
    LargeAreaChartCustom,
    LineChartCustom,
    PieChartCustom,
    RadarChartCustom,
    TableCustom
} from "../index";
import {DraggableModalProvider} from '@cubetiq/antd-modal'
import '@cubetiq/antd-modal/dist/index.css'
import {ChartQueryContext} from "../../../context/chartQueryContext/ChartQueryContextProvider";
import axios from "../../../api/axios";
import {AuthContext} from "../../../context/AuthContext";
import TableOutlined from "@ant-design/icons/es/icons/TableOutlined";
import {useFilter} from "../../../context/FilterContext";
import DotChartOutlined from "@ant-design/icons/es/icons/DotChartOutlined";
import PlusOutlined from "@ant-design/icons/es/icons/PlusOutlined";
import MinusCircleOutlined from "@ant-design/icons/es/icons/MinusCircleOutlined";
import {useForm} from 'antd/lib/form/Form';
import RadarChartOutlined from "@ant-design/icons/es/icons/RadarChartOutlined";
import ModalWithButton from "./ModalWithButton";
import "./all.css";
import QuerySaveForm from "./QuerySaveForm";
import SavedQueries from "./SavedQueries";

//const QUERY_URL = '/api/public/query';
const QUERY_URL = '/query';
const {Option} = Select;

const divStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    maxWidth: '100vw'
};

const ChartDrawer = ({open, setOpen}) => {
    const [form] = useForm();
    const [modals, setModals] = useState([]);
    const [modal, setModal] = useState(false);
    const [comparableColumns, setComparableColumns] = useState([]);
    const [comparingColumns, setComparingColumns] = useState([]);
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    });

    const draggleRef = useRef(null);
    const auth = useContext(AuthContext);
    const headers = {
        'Content-Type': 'application/json;charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization': `Bearer ${auth.token}`,
        'withCredentials': true
    };
    const {chartQuery, filterKey} = useContext(ChartQueryContext);
    useEffect(() => {
        setComparableColumns(chartQuery?.query?.fields?.filter((col) => col.is_comparable === true));
        setComparingColumns(chartQuery?.query?.fields?.filter((col) => col.is_main_comparing === true));
        form.resetFields();
    }, [chartQuery]);
    const {filterValues} = useFilter();
    const filterData = filterValues[filterKey] || [];
    const showingTypesAll = [
        {value: 'table', label: "Жадвал", icon: <TableOutlined/>},
        {value: 'pie_chart', label: "Пирог диаграммаси", icon: <PieChartOutlined/>},
        {value: 'bar_chart', label: "Устунли диаграмма", icon: <BarChartOutlined/>},
        {value: 'line_chart', label: "Чизиқли диаграмма", icon: <LineChartOutlined/>},
        {value: 'area_chart', label: "Ҳудудли диаграмма", icon: <AreaChartOutlined/>},
        {value: 'bubble_chart', label: "Пуфакчали диаграмма", icon: <DotChartOutlined/>},
        {value: 'radar_chart', label: "Полигоналли диаграмма", icon: <RadarChartOutlined/>},
        {value: 'large_area_chart', label: "Катта майдонли диаграммаси", icon: <AreaChartOutlined/>},
    ];
    const [operators, setOperators] = useState([
        {value: 'sum', label: 'Йиғиндиси'},
        {value: 'avg', label: 'Ўртачаси'},
        {value: 'min', label: 'Паст қийматдагилари'},
        {value: 'max', label: 'Юқори қийматдагилари'},
        {value: 'count', label: 'Сони'},
    ]);
    const [showingType, setShowingType] = useState('');
    const onClose = () => {
        setOpen(false);
    };
    const onFinish = async (values, query = chartQuery, title = '') => {
        const comparing_by = values.comparing_by_columns.map(column => column.comparing_by);
        try {
            const chartCols = [];
            const sorted_fields = query.query.fields.map((field) => {
                const comparingColumn = values.comparing_columns.filter((col) => col.comparing_column === field.column);
                // if (comparingColumn) chartCols.push(...comparingColumn.map(column => column.comparing_column));
                if (comparing_by.includes(field.column)) {
                    if (field.format && field.type) {
                        return {
                            column: field.column,
                            format: field.format,
                            type: field.type,
                        }
                    }
                    return {
                        column: field.column,
                    };
                } else if (comparingColumn && comparingColumn.length !== 0) {
                    return comparingColumn.map(column => {
                        let col = `${column.comparing_column}$${column.comparing_column_type}`;
                        chartCols.push(col);
                        return {
                            column: column.comparing_column,
                            alias: col,
                            function: {
                                type: column.comparing_column_type
                            }
                        };
                    });
                }
                return null;
            }).filter((field) => field !== null && field !== []);
            const sorted_fields_done = [];
            sorted_fields.forEach(item => {
                if (Array.isArray(item)) {
                    for (let column of item) {
                        sorted_fields_done.push(column);
                    }
                } else {
                    sorted_fields_done.push(item);
                }
            });
            const orderBy = values.comparing_by_columns.map((item) => {
                return {column: item.comparing_by, direction: item.comparing_by_type};
            });
            const dataChart = {
                query: {
                    id: query.query.id || query.query.ID,
                    source: query.query.source,
                    context: query.query.context,
                    fields: sorted_fields_done,
                    filters: filterData,
                    groupBy: comparing_by,
                    orderBy
                }
            };
            const {data} = await axios.post(QUERY_URL, dataChart, {
                headers: headers,
                withCredentials: false
            });

            const allNodes = values.showType.map((type) => {
                const ChartComponent = {
                    line_chart: LineChartCustom,
                    area_chart: AreaChartCustom,
                    bar_chart: BarChartCustom,
                    pie_chart: PieChartCustom,
                    table: TableCustom,
                    radar_chart: RadarChartCustom,
                    bubble_chart: BubbleChartCustom,
                    bar_race: BarRaceCustom,
                    large_area_chart: LargeAreaChartCustom
                }[type.key];
                const res = new ChartComponent(chartCols, comparing_by, data, [...query.query.fields]);
                return res.draw();
            });
            setModals(prev => [...prev, {
                initialHeight: 400,
                initialWidth: 500,
                title,
                content: React.createElement('div', {style: divStyle}, [...allNodes])
            }]);
        } catch (error) {
            console.error(error);
        }
    };
    const onChangeShowType = (value) => {
        setShowingType(value);
    };
    const onStart = (_event, uiData) => {
        const {clientWidth, clientHeight} = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    const notify = (text) => {
        /*toast.error(text, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });*/
    };
    const showSavedQueries = () => {
        setModal(true);
    };
    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };
    const onFinishFailed = (errorInfo) => {
        for (let error of errorInfo.errorFields) {
            notify(error.errors[0]);
        }
    };
    return (
        <>
            <Drawer
                title="Маълумотларни ишлаб чиқариш"
                placement={"right"}
                closable={false}
                onClose={onClose}
                open={open}
                key={"left"}
                size={"large"}
                extra={
                    <Space>
                        <Button onClick={showSavedQueries}>
                            Сақланган диаграммалар
                        </Button>
                        <Button danger onClick={onClose}>
                            Чиқиш
                        </Button>
                    </Space>
                }
            >
                <SavedQueries onFinish={onFinish} modal={modal} setModal={setModal}/>
                <Row gutter={[8, 24]} style={{marginLeft: 0, marginRight: 0}}>
                    <Col span={24}>
                        <Form
                            name="basic"
                            initialValues={{remember: true}}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            style={{marginTop: 30}}
                            autoComplete="off"
                            form={form}
                        >
                            <Form.Item label="Диаграмма тури" name="showType"
                                       rules={[{required: true, message: 'Илтимос, диаграмма туриини танланг!'}]}>
                                <Select
                                    showSearch
                                    mode="multiple" // Enables multi-select
                                    placeholder="Диаграмма турини танланг!"
                                    onChange={onChangeShowType}
                                    labelInValue // This prop will allow you to use custom labels
                                    optionLabelProp="label" // Specify which attribute to use as the label
                                    style={{width: '100%'}}
                                >
                                    {showingTypesAll?.map((item) => (
                                        <Option key={item.value} value={item.value} label={item.label}>
                                            {item.icon} {item.label}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.List name="comparing_by_columns">
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map(({key, name, ...restField}) => (
                                            <div key={key} style={{display: 'flex', gap: 10}}>
                                                <Form.Item {...restField} name={[name, 'comparing_by']} label="Устун"
                                                           rules={[{
                                                               required: true,
                                                               message: 'Илтимос, асосий кўрсаткични танланг!'
                                                           }]}>
                                                    <Select
                                                        showSearch
                                                        placeholder="Асосий кўрсаткични танланг!"
                                                        onChange={handleChange}
                                                        style={{width: 250}}
                                                        options={comparingColumns?.map((item) => ({
                                                            value: item.column,
                                                            label: item.label,
                                                        }))}
                                                    />
                                                </Form.Item>

                                                <Form.Item {...restField} name={[name, 'comparing_by_type']} rules={[{
                                                    required: true,
                                                    message: 'Илитмос, асосий кўрсаткични тартибланишини танланг!'
                                                }]}>
                                                    <Select
                                                        placeholder="Асосий кўрсаткични тартибланишини танланг!"
                                                        style={{width: 250}}
                                                        options={[
                                                            {value: 'asc', label: 'Ўсиш тартибида'},
                                                            {value: 'desc', label: 'Пасайиш тартибида'}
                                                        ]}/>
                                                </Form.Item>
                                                <MinusCircleOutlined style={{marginTop: 8}}
                                                                     onClick={() => remove(name)}/>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block
                                                    icon={<PlusOutlined/>}>
                                                Янги асосий кўрсаткич устунни киритиш!
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>

                            <Form.List name="comparing_columns">
                                {(fields, {add, remove}) => (
                                    <>
                                        {fields.map(({key, name, ...restField}) => (
                                            <div key={key} style={{display: 'flex', gap: 10}}>
                                                <Form.Item {...restField} name={[name, 'comparing_column']}
                                                           label="Устун" rules={[{
                                                    required: true,
                                                    message: 'Илтимос, жамланиладиган устунни танланг!'
                                                }]}>
                                                    <Select
                                                        showSearch
                                                        placeholder="Жамланиладиган устунни танланг!"
                                                        onChange={handleChange}
                                                        style={{width: 300}}
                                                        options={comparableColumns?.map((item) => ({
                                                            value: item.column,
                                                            label: item.label,
                                                        }))}
                                                    />
                                                </Form.Item>

                                                <Form.Item {...restField} name={[name, 'comparing_column_type']}
                                                           rules={[{
                                                               required: true,
                                                               message: 'Илитмос, жамланиш кўрсаткичини танланг!'
                                                           }]}>
                                                    <Select style={{width: 200}} options={operators}
                                                            placeholder="Жамланиш кўрсаткичини танланг!"/>
                                                </Form.Item>
                                                <MinusCircleOutlined style={{marginTop: 8}}
                                                                     onClick={() => remove(name)}/>
                                            </div>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" onClick={() => add()} block
                                                    icon={<PlusOutlined/>}>
                                                Янги жамланиладиган устунни киритиш!
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                            <div style={{display: 'flex', gap: '10px', justifyContent: 'center'}}>
                                <Button ghost type="primary" htmlType="submit">
                                    Шакллантириш
                                </Button>
                                <QuerySaveForm form={form}/>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Drawer>

            <DraggableModalProvider>
                {modals.map((modal, index) => (
                    <ModalWithButton modal={modal} key={index}/>
                ))}
            </DraggableModalProvider>

        </>
    );
};

export default ChartDrawer;
