import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Button, Checkbox, Divider, Drawer, Form, Input, Select, Space, Tabs, Typography} from "antd";
import axios from "../../api/axios";
import {getBackendApiBaseUrl} from "../../api/axios"
import {AuthContext} from "../../context/AuthContext";
//import cors from "cors";
import {useNavigate} from "react-router-dom";
import {useAuth} from '../../hooks/auth.hook';
import getFilials from "../../api/refs/filials";
import EIMZO from "../../libs/eimzo/Eimzo";
import {AppLoad} from "../../libs/eimzo/e-imzo-init";
import {microAjax} from "../../libs/eimzo/micro-ajax";
import {uiHandleError, uiHideProgress, uiShowMessage, uiShowProgress} from "../../libs/eimzo/e-imzo-init";
import certsEcp from "./CertsEcp";
import CertsEcp from "./CertsEcp";

const {Option} = Select;

const LOGIN_URL = '/user/login';
const CHANGE_PWD_URL = '/user/changePassword';
// const proxy = require('http-proxy-middleware');

const Login = () => {
    let navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/layout', {replace: true}), [navigate]);

    //const { auth, setAuth } = useContext(AuthContext);
    const auth = useContext(AuthContext);
    // const userRef = useRef();
    const errRef = useRef();
    //const {token, userId, login} = useAuth();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [success, setSuccess] = useState(false);

    const [certificates, setCertificates] = useState(null);
    const [certKeys, setCertKeys] = useState(null);
    const [selectedCert, setSelectedCert] = useState(null);

    const [openCerts, setOpenCerts] = useState(false);
    const showDrawerCerts= () => {
        setOpenCerts(true);
    };
    const onCloseCerts = () => {
        setOpenCerts(false);
    };

    const EIMZOClient = new EIMZO();

    // useEffect(() => {
    //     userRef.current.focus();
    // }, [])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    /*useEffect(() => {
        const listAllKeys = async () => {
            const certs = await EIMZOClient.install();
            const data = await EIMZOClient.listAllUserKeys();
            console.log(data,'lololo')
            console.log(data[0].CN,'data.CN')
            setCertKeys(data)
            setCertificates(data);
        };

        listAllKeys();
        AppLoad()

        console.log("certificates", certificates);
    }, []);*/

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };


    const getBackendApiBaseUrl111 = () => {
        const hostname = window.location.hostname;
        const path = window.location.pathname;

        console.log(hostname,'hostname')

        if (hostname === 'mbat.chamber.uz') {
            return 'mbatbackend.chamber.uz';
            //} else if (hostname === 'localhost'  || (hostname === 'mbatbackend.chamber.uz' && path.startsWith('/test_be')) ) {
        } else if (hostname === 'localhost') {
            return 'mbatbackend.chamber.uz/test_be';
        }

        return 'mbatbackend.chamber.uz';
    };

    const handleSubmit = async (e) => {

        try {
            const headers1 = {'Content-Type':'application/json;charset=utf-8', 'Access-Control-Allow-Origin':'*', 'Access-Control-Allow-Credentials':'true', 'withCredentials': 'true'}
            const headers2 = {'Content-Type':'application/json;charset=utf-8', 'Authorization':''}

            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ login: user, password: pwd }),
                {
                    headers: headers1,
                    proxy: {
                        protocol: 'https',
                        //host: '217.30.161.190',
                        //host: '172.24.255.74',
                        //host: 'mbat.chamber.uz',
                        //host: 'mbatbackend.chamber.uz',
                        //host: 'backend.ssp-mbat.uz',
                        host: getBackendApiBaseUrl()
                        //port: 10006,
                        //port: 9595,
                    },
                    crossdomain: true,
                    withCredentials: false
                }
            );

            const accessToken = response?.headers?.jwt;

            const empId = response?.data?.id;
            const empName = response?.data?.fio;
            const empFilial = response?.data?.filial;
            const empInn = response?.data?.tin;

            const dataFilial = await getFilials();
            const filial = dataFilial.find( el => { return el.code === empFilial } )
            const filialName = filial?.name

            auth.login(accessToken, user, empId, empName, empFilial, filialName, empInn)
            setUser('');
            setPwd('');
            setSuccess(true);
            //navigate("layout",success);
            handleOnClick();
            useAuth.login(accessToken, user, empId, empName, empFilial, filialName, empInn)
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                if (err.code === 'ERR_NETWORK') {setErrMsg('Нет соединения с сервером'); return}
                setErrMsg('Login Failed');
                if (err.response?.data?.message) {
                    alert(err.response.data.message);
            }
            }
            // errRef.current.focus();
        }

    };

    const handleEcpChange = (value, option) => {

        console.log(value)
        console.log(option)
        const targetObject = certKeys.find(item => item.TIN === value);
        console.log(targetObject)
        setSelectedCert(targetObject)

    }

    const authEcp = function (keyId, challenge, callback){
        EIMZOClient.createPkcs7(keyId, challenge, null, function(pkcs7){
            microAjax('auth.php', function (data, s) {
                uiHideProgress();
                if(s.status != 200){
                    uiShowMessage(s.status + " - " + s.statusText);
                    return;
                }
                try {
                    var data = JSON.parse(data);
                    if (data.status != 1) {
                        uiShowMessage(data.status + " - " + data.message);
                        return;
                    }
                    callback(data.redirect);
                } catch (e) {
                    uiShowMessage(s.status + " - " + s.statusText + ": " + e);
                }

            }, 'keyId=' + encodeURIComponent(keyId) + '&pkcs7=' + encodeURIComponent(pkcs7));
        }, uiHandleError, false);
    }

    const getChallenge = function (callback){
        microAjax('/frontend/challenge', function (data, s) {
            if(s.status != 200){
                uiShowMessage(s.status + " - " + s.statusText);
                return;
            }
            try {
                var data = JSON.parse(data);
                if (data.status != 1) {
                    uiShowMessage(data.status + " - " + data.message);
                    return;
                }
                callback(data.challenge);
            } catch (e) {
                uiShowMessage(s.status + " - " + s.statusText + ": " + e);
            }
        });
    }
    const handleEcpLogin = async () => {
        try {
            const result = await EIMZOClient.loadKey(selectedCert);
            console.log(result);

            // Handle the result here
        } catch (error) {
            console.error(error);
            // Handle the error here
            }
        return false;
        uiShowProgress();

        getChallenge(function(challenge){
            var keyType = 'pfx';
            //var keyType = document.testform.keyType.value;
            if(keyType==="idcard"){
                var keyId = "idcard";

                /*authEcp(keyId, challenge, function(redirect){
                    window.location.href = redirect;
                    uiShowProgress();
                });*/

            } else {
                var itm = document.testform.key.value;
                if (itm) {
                    var id = document.getElementById(itm);
                    var vo = JSON.parse(id.getAttribute('vo'));

                    EIMZOClient.loadKey(vo, function(id){
                        var keyId = id;

                        /*authEcp(keyId, challenge, function(redirect){
                            window.location.href = redirect;
                            uiShowProgress();
                        });*/

                    }, uiHandleError);
            } else {
                    uiHideProgress();
            }
        }
        });

    }

    return (
        <div className='login-container'>

            <div className="login-illustrate">

            </div>

            <Space direction="vertical" size="middle" style={{ display: "flex", justifyContent:"center", alignItems: "center", height: "100%", width: "100%", position: "relative", overflow: "hidden", marginTop:"80px" }}>

                <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

                <Divider orientation="center" plain style={{color:"rgb(20, 20, 20)", margin: 0}}>
                    <h2>Тизимга кириш</h2>
                </Divider>

                <Form
                    name="basic"
                    className="login-form"
                    layout="vertical"
                    initialValues={{
                        remember: false,
                    }}
                    //onFinish={onFinish}
                    onFinish={handleSubmit}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    style={{width:"300px"}}

                >
                    <Form.Item
                        /*label="Логин"*/
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Логин киритинг!',
                            },
                        ]}
                    >
                        <Input
                            id="username"
                            autoComplete="off"
                            value={user}
                            onChange={(e) => setUser(e.target.value)}
                            required
                            placeholder="Логин"
                            style={{backgroundColor: 'white', color: "rgba(0, 0, 0, 0.88)"}}
                        />
                    </Form.Item>

                    <Form.Item
                        /*label="Пароль"*/
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Пароль киритинг!',
                            },
                        ]}
                    >
                        <Input.Password
                            id="password"
                            value={pwd}
                            onChange={(e) => setPwd(e.target.value)}
                            required
                            placeholder="Пароль"
                            style={{backgroundColor: 'white', color: "rgba(0, 0, 0, 0.88)"}}
                        />
                    </Form.Item>

                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                    >
                        <Checkbox>Эслаб колиш</Checkbox>

                        <a className="login-form-forgot" href="#" style={{float:"right"}}>
                            Парол эсдан чикдими?
                        </a>

                    </Form.Item>

                    <Form.Item>

                        <Button type="primary" className="btn-grad login-form-button" htmlType="submit" style={{width:"100%"}}>
                            Кириш
                        </Button>

                    </Form.Item>

                </Form>

            </Space>



        </div>
    );
}

export default Login;

